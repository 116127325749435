/** @jsx jsx */
import React, { useState, useEffect } from "react";
import { Container, jsx, Button } from "theme-ui";
import Modal from "react-bootstrap/esm/Modal";
import { IoIosClose } from "react-icons/io";
import {
  PostEmailTemplate,
  GetAllEmailTemplates,
  UpateEmailTemplate,
} from "../../services/email-service";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Spinner from "react-bootstrap/Spinner";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { validateJwtToken, getJwtTokenRoles } from "../../services/auth-service";
// import "react-quill/dist/quill.snow.css";
// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.bubble.css";
import { navigate } from "gatsby";
import loadable from "@loadable/component";
import 'react-quill/dist/quill.snow.css'; 

const UserEmailSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  subject: Yup.string().required("Subject is required"),
  body: Yup.string().required("Body is required"),
});

const CreateEmailTemplate = (props: any) => {
  if (!validateJwtToken()) return null;

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [showEmailTemplateModal, setShowEmailTemplateModal] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [message, setMessage] = useState("");
  const [error, setError] = useState(true);
  const errorMessage = "Something went wrong, please try again.";
  const [isMounted, setIsMounted] = useState(false);
  const [isClient, setIsClient] = useState(false);

  const ReactQuill = loadable(() => import("react-quill"));
  
  useEffect(() => {

    const roles = getJwtTokenRoles();

    if (!roles.includes("FullAccess") && !roles.includes("SendEmails")) {
      navigate('/401');
    }
    else {
      const getTemplates = async () => {
        const emailTemplates = await GetAllEmailTemplates();
        setEmailTemplates(emailTemplates.data);
        setLoading(false);
      };
      getTemplates();
    }

    setIsMounted(true);
    setIsClient(typeof window !== 'undefined' && typeof document !== 'undefined');
  }, []);

  const deleteTemplate = async (
    id: any,
    name: string,
    subject: string,
    body: string
  ) => {
    setDeleting(true);
    setError(false);
    setMessage("");

    const date = new Date();
    await UpateEmailTemplate({
      id: id,
      name: name,
      subject: subject,
      body: body,
      dateDeleted: date.toISOString(),
    }).then((result: any) => {
      if (result.status === 200) {
        setEmailTemplates(emailTemplates.filter((x) => x.id !== id));
      } else {
        setError(true);
        setHideMessage(errorMessage);
      }
      setDeleting(false);
    });
  };

  const setHideMessage = (message: any) => {
    setMessage(message);
    setTimeout(() => {
      setMessage("");
    }, 2000);
  };

  const handleSetBody = (formik: any, value: string) => {
    formik.setFieldValue('body', value);
  };

  const formats = [
    'header', 'font', 'size', 'color', 'background', 'bold', 'italic',
    'underline', 'strike', 'blockquote', 'list', 'bullet', 'link', 'image', 'video',
    'custom'
  ];

  const modules = {
    toolbar: {
      container: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' },
        { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ],
    },
    clipboard: {
      matchVisual: false
    },
    history: {
      delay: 1000,
      maxStack: 50,
      userOnly: true
    },
    keyboard: {
      bindings: {
        tab: false,
        'shift+tab': false
      }
    },
  };

  return (
    <Container py="40px" sx={styles.container}>
      <div className="main-wrapper">
        <h2 sx={styles.header}>Email Templates</h2>
        <hr className="bar-indent-center" sx={styles.headerline} />
      </div>
      {!loading ? (

        <React.Fragment>
          {emailTemplates.length == 0 && (
            <React.Fragment>
              <p style={{ textAlign: 'center', display: 'block' }}>You have no templates.</p>
            </React.Fragment>
          )}

          <Accordion allowZeroExpanded={true}>
            {emailTemplates.map(({ name, body, subject, id }) => (
              <AccordionItem uuid={id} key={id}>
                <AccordionItemHeading>
                  <AccordionItemButton>{name}</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="content" sx={styles.content}>
                    <Formik
                      initialValues={{
                        title: name,
                        body: body,
                        subject: subject,
                      }}
                      enableReinitialize={true}
                      validationSchema={UserEmailSchema}
                      onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        setUpdating(true);

                        await UpateEmailTemplate({
                          id: id,
                          name: values.title,
                          body: values.body,
                          subject: values.subject,
                        }).then((result: any) => {
                          if (result.status === 200) {
                            setError(false);
                            setHideMessage("Template updated sucessfully!");
                          } else {
                            setError(true);
                          }
                        });
                        setUpdating(false);
                      }}>
                      {(formik) => {
                        return (
                          <Form id={`EmailTemplateForm_${id}`}>
                            <div className="post-form">
                              <div className="row" sx={styles.form.details}>
                                <div className="col-lg-6 col-md-6">
                                  <div className="form-group">
                                    <label
                                      htmlFor={`title_${id}`}
                                      sx={styles.form.label}
                                    >
                                      Title
                                    </label>
                                    <Field
                                      name="title"
                                      id={`title_${id}`}
                                      className="form-control"
                                      sx={styles.form.input}
                                      placeholder="Order Received"
                                    />
                                    <ErrorMessage
                                      name="title"
                                      component="div"
                                      sx={styles.input.error}
                                    />
                                  </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                  <div className="form-group">
                                    <label htmlFor="subject" sx={styles.form.label}>
                                      Subject
                                    </label>
                                    <Field
                                      name="subject"
                                      id={`subject_${id}`}
                                      type="text"
                                      className="form-control"
                                      sx={styles.form.input}
                                      placeholder="Thank you"
                                    />
                                    <ErrorMessage
                                      name="subject"
                                      component="div"
                                      sx={styles.input.error}
                                    />
                                  </div>
                                </div>

                                <div>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label htmlFor="body" sx={styles.form.label}>
                                        Body
                                      </label>

                                      {isMounted && isClient && (
                                        <ReactQuill
                                          id={`body_${id}`}
                                          className="text-area"
                                          value={formik.values.body}
                                          onChange={(value: any) => handleSetBody(formik, value)}
                                          formats={formats}
                                          modules={modules}
                                          style={{ backgroundColor: 'white' }}
                                        />
                                      )}
                                      <ErrorMessage
                                        name="body"
                                        component="div"
                                        sx={styles.input.error}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div>
                                {message !== "" && (
                                  <p
                                    className={
                                      !error ? "text-success" : "text-danger"
                                    }
                                    sx={styles.message}
                                  >
                                    {message}
                                  </p>
                                )}
                                <Button
                                  id={`save_${id}`}
                                  sx={styles.button}
                                  type="submit"
                                  // disabled={isSubmitting}
                                  style={{ margin: "0!important" }}
                                >
                                  {!updating && <span>Update</span>}

                                  {updating && (
                                    <Spinner animation="border" role="status">
                                      <span className="sr-only"></span>
                                    </Spinner>
                                  )}
                                </Button>

                                <Button
                                  type="button"
                                  className="button"
                                  id={`delete_${id}`}
                                  sx={styles.buttonDelete}
                                  onClick={() => {
                                    deleteTemplate(id, name, subject, body);
                                  }}
                                >
                                  {!deleting && <span>Delete</span>}

                                  {deleting && (
                                    <Spinner animation="border" role="status">
                                      <span className="sr-only"></span>
                                    </Spinner>
                                  )}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        )
                      }}
                    </Formik>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </React.Fragment>
      ) : (
        <Spinner animation="border" role="status">
          <span className="sr-only"></span>
        </Spinner>
      )}
      <Button
        onClick={() => setShowEmailTemplateModal(true)}
        sx={styles.button}
        id="createTemplate"
      >
        Create Template
      </Button>
      <div className="modal" id="emailTemplateModal">
        <div className="modal-dialog modal-xl">
          <div className="modal-content modal-dialog modal-xl">
            <Modal
              show={showEmailTemplateModal}
              onHide={() => setShowEmailTemplateModal(false)}
              keyboard={true}
              centered
              backdrop="static"
              keyboard="static"
            >
              <Modal.Header>
                <Modal.Title>
                  <h3 sx={styles.header}>Create Template</h3>
                  <IoIosClose
                    sx={styles.modal.close}
                    onClick={() => setShowEmailTemplateModal(false)}
                  />
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>

                <Formik
                  initialValues={{
                    title: "",
                    body: "",
                    subject: "",
                  }}
                  enableReinitialize={true}
                  validationSchema={UserEmailSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    setSubmitting(true);
                    setSaving(true);

                    await PostEmailTemplate({
                      name: values.title,
                      body: values.body,
                      subject: values.subject,
                    }).then((result: any) => {
                      if (result.status === 200) {
                        setError(false);
                        emailTemplates.push({
                          id: result.data,
                          name: values.title,
                          body: values.body,
                          subject: values.subject,
                        });
                        setShowEmailTemplateModal(false);
                      } else {
                        setError(true);
                      }
                    });
                    setSaving(false);
                  }}>
                  {(formik) => {
                    return (
                      <Form id={`EmailTemplateForm`}>
                        <div className="post-form">
                          <div className="row" sx={styles.form.details}>
                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label htmlFor="title" sx={styles.form.label}>
                                  Title
                                </label>
                                <Field
                                  name="title"
                                  id="title"
                                  className="form-control"
                                  sx={styles.form.input}
                                  placeholder="Order Received"
                                />
                                <ErrorMessage
                                  name="title"
                                  component="div"
                                  sx={styles.input.error}
                                />
                              </div>
                            </div>

                            <div className="col-lg-6 col-md-6">
                              <div className="form-group">
                                <label htmlFor="subject" sx={styles.form.label}>
                                  Subject
                                </label>
                                <Field
                                  name="subject"
                                  id={"subject"}
                                  type="text"
                                  className="form-control"
                                  sx={styles.form.input}
                                  placeholder="Thank you"
                                />
                                <ErrorMessage
                                  name="subject"
                                  component="div"
                                  sx={styles.input.error}
                                />
                              </div>
                            </div>

                            <div>
                              <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                  <label htmlFor="body" sx={styles.form.label}>
                                    Body
                                  </label>
                                  {isMounted && isClient && (
                                    <ReactQuill
                                      name="body"
                                      className="text-area"
                                      formats={formats}
                                      value={formik.values.body} // Add this line
                                      modules={modules}
                                      onChange={(value: any) => handleSetBody(formik, value)}
                                      style={{ backgroundColor: 'white' }}
                                    />
                                  )}
                                  <ErrorMessage
                                    name="body"
                                    component="div"
                                    sx={styles.input.error}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            {message !== "" && (
                              <p
                                className={
                                  !error ? "text-success" : "text-danger"
                                }
                                sx={styles.message}
                              >
                                {message}
                              </p>
                            )}
                            <Button
                              id={`create`}
                              sx={styles.button}
                              type="submit"
                              style={{ marginTop: "0!important" }}
                            >
                              {!saving && <span>Create</span>}

                              {saving && (
                                <Spinner animation="border" role="status">
                                  <span className="sr-only"></span>
                                </Spinner>
                              )}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
    </Container>
  );
};

const styles = {
  container: {
    "@media only screen and (max-width: 768px)": {
      paddingLeft: "10px!important",
      paddingRight: "10px!important",
    },
  },
  input: {
    error: {
      fontSize: "13px",
      paddingBottom: "5px",
      color: "#dc3545",
    },
  },
  message: {
    float: "unset!important",
    fontSize: "16px",
    textAlign: "center",
  },
  header: {
    textAlign: "center",
    display: "block",
  },
  headerline: {
    // marginBottom: "3rem",
  },
  button: {
    fontSize: "14px",
    color: "white",
    fontWeight: "600",
    marginTop: "2rem",
    padding: "12.5px 25px",
    borderRadius: "10px",
    background: "#00d4c5",
    width: "100%",
    textTransform: "uppercase",
  },
  modal: {
    close: {
      position: "absolute",
      right: "3px",
      fontSize: "40px",
      top: "5px",
      cursor: "pointer",
    },
    title: {
      fontSize: "17px",
      margin: 0,
    },
  },
  form: {
    details: {
      marginTop: 30,
      marginBottom: 30,
    },
    label: {
      fontWeight: "600",
      marginBottom: "0.5em",
      color: "#2F3E57",
    },
    input: {
      color: "#2F3E57",
      width: "100%",
      fontSize: "1rem",
      fontWeight: "400",
      borderRadius: 6,
      padding: "10px",
      opacity: ".7",
      marginBottom: "10px",
      borderWidth: "1px",
      borderStyle: "solid",
      boxShadow: "none",
      transition: "border-color 0.2s ease",
      borderColor: "#E4E4E4",
      "&:focus": {
        outline: "none",
        boxShadow: "none",
        borderColor: "darkgray",
      },
      error: {
        fontSize: "13px",
        marginTop: "-5px",
        paddingBottom: "5px",
        color: "#dc3545",
      },
    },
  },
  buttonDelete: {
    fontSize: "14px",
    background: "#dc3545",
    fontWeight: "600",
    marginTop: "16px",
    padding: "12.5px 25px",

    borderRadius: "10px     10px      10px           10px",
    width: "100%",
    textTransform: "uppercase",
  },
  heading: {
    fontWeight: 500,
  },
  item: {
    cursor: "pointer",
    "list-style-type": "none",
    textAlign: "left",
    width: "100%",
    border: "3px solid #e4e4e4",
    margin: "6px",

    ":hover": {
      opacity: ".9",
    },
    ":active": {
      backgroundColour: "#8b0000",
    },
  },
  content: {
    backgroundColor: "#f5f5f5",
    color: "black",
    overflow: "hidden",
    padding: "20px!important",
    borderRadius: "5px",
    "&.show": {
      display: "none",
    },
  },
  title: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "18px",
    paddingRight: "18px",
    backgroundColor: "#eeee",
    color: "black",
    textTransform: "uppercase",
  },
  icon: {
    paddingRight: "18px",
  },
  spinner: {
    marginTop: "40px",
    textAlign: "center",
    padding: "20px",
    display: "block",
    ".spinner-border": {
      height: "4em",
      width: "4em",
      color: "#00d4c5",
    },
  },
};
export default CreateEmailTemplate;
